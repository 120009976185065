import { HttpClient } from '@angular/common/http';
import { inject, Injectable, signal } from '@angular/core';
import { BehaviorSubject, catchError, filter, map, of } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SearchService {
  protected http = inject(HttpClient);
  protected $searchResponse = new BehaviorSubject<any>(null);
  public searchResponse$ = this.$searchResponse
    .asObservable()
    .pipe(filter((data) => Boolean(data)));

  protected latestQueryParam: any = {};

  protected $searchCategoryResponse = new BehaviorSubject<any>(null);
  public searchCategoryResponse$ = this.$searchCategoryResponse
    .asObservable()
    .pipe(filter((data) => Boolean(data)));

  public isLastCategoryPage = false;

  protected latestQueryParamCategory: any = {};
  protected lastCategoryName = '';

  protected paginationKeys = ['currentPage', 'pageSize', 'sort', 'sortDir'];

  protected $autoCompleteResults = new BehaviorSubject(null);
  protected autoCompleteResults$ = this.$autoCompleteResults.asObservable();
  public isLastAutoCompletePage = false;
  $latestSearches = new BehaviorSubject<Array<string>>([]);
  latestSearches$ = this.$latestSearches.asObservable();

  getSearchResult(queryParams: any, force = false) {
    queryParams = {
      ...{ currentPage: 1, pageSize: 24 },
      ...queryParams,
    };
    queryParams.currentPage = Math.max(Number(queryParams.currentPage), 1);
    let isRefreshReq = false;
    const entries: any = Object.entries(queryParams)
      .filter(([key, value]) => !!value)
      .map(([key, value]) => {
        if (this.paginationKeys.some((pKey) => pKey === key)) {
          return [`pagination.${key}`, value];
        }
        if (this.latestQueryParam?.[key] !== value) {
          isRefreshReq = true;
        }
        return [`filters.${key}`, value];
      });
    const queryParam = new URLSearchParams(Object.fromEntries(entries));
    const queryString = queryParam.toString();
    if (isRefreshReq) {
      console.log('Girdiiiiiii');
      this.isLastAutoCompletePage = false;
      this.$searchResponse.next({
        ...(this.$searchResponse.getValue() || {}),
        results: [],
        skipLoadingState: true,
      });
    }
    if (
      this.$searchResponse.getValue()?.pagination &&
      this.$searchResponse.getValue().pagination.currentPage * 24 >
        this.$searchResponse.getValue().results.length
    ) {
      this.$searchResponse.next({
        ...(this.$searchResponse.getValue() || {}),
        skipLoadingState:
          (this.$searchResponse.getValue().pagination.currentPage !== 1 &&
            !this.isLastAutoCompletePage) ||
          isRefreshReq,
      });
    }
    if (
      (!this.$searchResponse.getValue() ||
        force ||
        this.latestQueryParam.toString() !== queryString) &&
      !this.isLastAutoCompletePage
    ) {
      this.latestQueryParam = queryParams;
      this.http
        .get(`${environment.apiHost}Search?${queryString}`)
        .pipe(
          map((response: any) => response?.data),
          catchError(() => {
            let currentResponse: any = structuredClone(
              this.$searchResponse.getValue()
            );
            return of({
              results: [],
              pagination: currentResponse?.pagination || {},
              filters: currentResponse?.filter || {},
            });
          })
        )
        .subscribe((response: any) => {
          if (!response) {
            return;
          }
          let currentResponse: any = structuredClone(
            this.$searchResponse.getValue()
          );
          if (!currentResponse) {
            currentResponse = { results: [] };
          }
          currentResponse.pagination = response.pagination;
          currentResponse.filters = response.filters;
          (currentResponse.results as Array<any>).splice(
            (response.pagination.currentPage - 1) *
              response.pagination.pageSize,
            response.results.length,
            ...response.results
          );
          if (!response.results.length) {
            this.isLastAutoCompletePage = true;
          }
          currentResponse.skipLoadingState = false;
          this.$searchResponse.next(currentResponse);
        });
    }
    return this.searchResponse$;
  }

  getNextPageResults() {
    const latestQueryParam: any = this.latestQueryParam;
    latestQueryParam['currentPage'] = (
      Number(latestQueryParam['currentPage'] || '0') + 1
    ).toString();
    this.getSearchResult(latestQueryParam, true);
  }

  getCategoryResult(categoryName: string, queryParams: any, force = false) {
    queryParams = {
      ...{ currentPage: 1, pageSize: 24 },
      ...queryParams,
    };
    let isRefreshReq = false;
    if (this.lastCategoryName !== categoryName) {
      isRefreshReq = true;
    }
    this.lastCategoryName = categoryName;
    queryParams.currentPage = Math.max(Number(queryParams.currentPage), 1);
    const entries: any = Object.entries(queryParams)
      .filter(([key, value]) => !!value)
      .map(([key, value]) => {
        if (this.paginationKeys.some((pKey) => pKey === key)) {
          return [`pagination.${key}`, value];
        }
        if (this.latestQueryParamCategory?.[key] !== value) {
          isRefreshReq = true;
        }
        return [`filters.${key}`, value];
      });
    const queryParam = new URLSearchParams(Object.fromEntries(entries));
    const queryString = queryParam.toString();
    if (isRefreshReq) {
      this.isLastCategoryPage = false;
      this.$searchCategoryResponse.next({
        ...(this.$searchCategoryResponse.getValue() || {}),
        results: [],
        skipLoadingState: true,
      });
    }
    if (
      this.$searchCategoryResponse.getValue().pagination &&
      this.$searchCategoryResponse.getValue().pagination.currentPage * 24 >
        this.$searchCategoryResponse.getValue().results.length
    ) {
      this.$searchCategoryResponse.next({
        ...(this.$searchCategoryResponse.getValue() || {}),
        skipLoadingState:
          (this.$searchCategoryResponse.getValue().pagination.currentPage !==
            1 &&
            !this.isLastCategoryPage) ||
          isRefreshReq,
      });
    }
    if (
      (!this.$searchCategoryResponse.getValue() ||
        force ||
        this.latestQueryParamCategory.toString() !== queryString) &&
      !this.isLastCategoryPage
    ) {
      this.latestQueryParamCategory = queryParams;
      this.http
        .get(
          `${environment.apiHost}Search/category/${categoryName}?${queryString}`
        )
        .pipe(
          map((response: any) => response?.data),
          catchError(() => {
            let currentResponse: any = structuredClone(
              this.$searchCategoryResponse.getValue()
            );
            return of({
              results: [],
              pagination: currentResponse?.pagination || {},
              filters: currentResponse?.filter || {},
            });
          })
        )
        .subscribe((response: any) => {
          if (!response) {
            return;
          }
          let currentResponse: any = structuredClone(
            this.$searchCategoryResponse.getValue()
          );
          if (!currentResponse) {
            currentResponse = { results: [] };
          }
          currentResponse.pagination = response.pagination;
          currentResponse.filters = response.filters;
          (currentResponse.results as Array<any>).splice(
            (response.pagination.currentPage - 1) *
              response.pagination.pageSize,
            response.results.length,
            ...response.results
          );
          if (!response.results.length) {
            this.isLastCategoryPage = true;
          }
          currentResponse.skipLoadingState = false;
          this.$searchCategoryResponse.next(currentResponse);
        });
    }
    return this.searchCategoryResponse$;
  }

  getNextCategoryPageResults() {
    const latestQueryParamCategory: any = this.latestQueryParamCategory;
    latestQueryParamCategory['currentPage'] = (
      Number(latestQueryParamCategory['currentPage'] || '0') + 1
    ).toString();
    this.getCategoryResult(
      this.lastCategoryName,
      latestQueryParamCategory,
      true
    );
  }

  getLatestSearches() {
    let searches = this.$latestSearches.getValue();
    if (!searches.length) {
      searches = JSON.parse(localStorage.getItem('latestSearches') || '[]');
      this.$latestSearches.next(searches);
    }
    return this.latestSearches$;
  }

  addLatestSearches(searchKey: string) {
    this.getLatestSearches();
    let searches = [...this.$latestSearches.getValue()];
    searches.unshift(searchKey);
    searches = Array.from(new Set(searches));
    if (searches.length > 5) {
      searches.splice(5, searches.length - 5);
    }
    this.$latestSearches.next(searches);
    localStorage.setItem('latestSearches', JSON.stringify(searches));
  }

  deleteLatestSearch(searchKey: string) {
    this.getLatestSearches();
    const searches = [...this.$latestSearches.getValue()].filter(
      (item) => item !== searchKey
    );
    this.$latestSearches.next(searches);
    localStorage.setItem('latestSearches', JSON.stringify(searches));
  }
  deleteAllLatestSearches() {
    this.$latestSearches.next([]);
    localStorage.setItem('latestSearches', '[]');
  }

  public getAutoCompleteResults(term?: string) {
    if (term && term.length > 2) {
      this.http
        .get(`${environment.apiHost}Search/autocomplete?searchText=${term}`)
        .pipe(map((response: any) => response.data))
        .subscribe((result) => {
          this.$autoCompleteResults.next(result);
        });
    } else {
      this.$autoCompleteResults.next(null);
    }
    return this.autoCompleteResults$;
  }
}
