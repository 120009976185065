<div
  class="flex flex-col w-full max-w-308 p-4 md:pb-0 z-51 md:h-auto"
  [ngClass]="[isExplorePage() ? 'h-[122px]' : 'h-14']"
>
  <div class="flex items-center justify-between gap-4 md:gap-12 flex-wrap">
    <a [routerLink]="['/'] | localize">
      <img
        class="h-[13px] md:h-5 ml-3 md:ml-0 order-1 md:order-none"
        src="/assets/images/biletzero.png"
      />
    </a>
    <div
      auto-suggest
      class="order-3 md:order-none w-full max-w-lg mx-auto md:block"
      [currentValue]="(queryParams$ | async)?.['searchText']"
      [class.hidden]="!isExplorePage()"
    ></div>
    <button
      class="flex items-center capitalize"
      [class.invisible]="isCitySelectionDisabled"
      (click)="openModal()"
    >
      {{ activeCity() }}
      <span class="liga-chevron-down text-sm leading-none ml-2 w-4"></span>
    </button>
  </div>
</div>
@if({isSmallScreen: isSmallScreen$ | async, categories: categories$ |async,
queryParams: queryParams$ | async}; as data){
<!-- Menu -->
@if (data.categories; as menu){ @if (!hideCategories){
<div
  class="flex justify-start no-scrollbar md:justify-center w-full md:w-auto max-w-full md:gap-x-0 overflow-auto md:overflow-visible z-50 snap-x snap-mandatory scroll-smooth items-center min-h-[69px] bg-black border-t border-t-[#4B4B4B] md:border-t-0"
>
  @if (!isExplorePage()){
  <!-- Main menu -->
  @for(item of menu; track i; let i = $index){
  <div
    class="border-b md:border-b-8 border-transparent hover:border-white text-sm md:text-base tracking-[2%] whitespace-nowrap snap-start px-4 first:pl-4 md:first:pl-0 last:pr-4 md:last:pr-0 first:ml-auto last:mr-auto"
    (mouseenter)="setSubMenuIndex(i, data.isSmallScreen)"
    (mouseleave)="setSubMenuIndex(-1, data.isSmallScreen)"
  >
    <a
      type="button"
      class="flex flex-col items-center justify-center py-3 md:py-5 w-auto px-1 md:px-9 capitalize bg-transparent min-h-0 md:min-h-16"
      [routerLink]="
        [
          '/',
          'events',
          activeCity() | slugify,
          item.slug || item.name | slugify
        ] | localize
      "
    >
      <span
        class="font-iconbz md:hidden text-base"
        [ngClass]="['liga-' + item.icon || '']"
      ></span>
      <span class="font-normal md:font-bold">{{ item.name }}</span>
    </a>
    @if(activeSubMenuIndex() === i &&
    menu[activeSubMenuIndex()].children?.length){
    <div class="absolute top-full inset-x-0 overflow-hidden z-50">
      <div
        class="flex justify-center bg-white pt-10 pb-14"
        @slideInTop
        @slideOutTop
      >
        <div
          class="grid grid-cols-[1fr_1fr_0.4fr] gap-20 text-black w-full max-w-308 md:px-4 xl:px-0"
        >
          @for(child of menu[activeSubMenuIndex()].children; track j; let j =
          $index){ @switch (child.type) { @case ('card') {
          <ng-container
            [ngTemplateOutlet]="cardTemp"
            [ngTemplateOutletContext]="{ $implicit: child }"
          ></ng-container>
          } @case ('banner') {
          <ng-container
            [ngTemplateOutlet]="bannerTemp"
            [ngTemplateOutletContext]="{ $implicit: child }"
          ></ng-container>
          } @case ('group') {
          <ng-container
            [ngTemplateOutlet]="groupTemp"
            [ngTemplateOutletContext]="{ $implicit: child }"
          ></ng-container>
          } @case ('basic-group') {
          <ng-container
            [ngTemplateOutlet]="groupTemp"
            [ngTemplateOutletContext]="{ $implicit: child }"
          ></ng-container>
          } @default {
          <ng-container
            [ngTemplateOutlet]="basicTemp"
            [ngTemplateOutletContext]="{ $implicit: child }"
          ></ng-container>
          } } }
        </div>
      </div>
    </div>
    }
  </div>
  }
  <!-- Main Menu loop ends -->
  }@else if (isExplorePage() ) { @for(child of exploreMenu; track $index) {
  <div class="first:pl-4 last:pr-4 snap-start h-full py-2 px-2">
    <a
      type="button"
      class="flex text-sm px-4 py-2 border border-white rounded-full capitalize bg-white bg-opacity-0 whitespace-nowrap"
      [routerLink]="
        (child.link === 'all' ? '/explore' : '/explore/' + child.link)
          | localize
      "
      [routerLinkActive]="['bg-opacity-20', child.link || 'no-link']"
      [queryParams]="{
        searchText: data.queryParams?.['searchText']
      }"
      [routerLinkActiveOptions]="{
        exact: false,
        paths: 'exact',
        matrixParams: 'exact',
        queryParams: 'ignored'
      }"
    >
      {{ child.link || "" | translate }}
    </a>
  </div>
  } }
</div>
}
<div
  class="block fixed inset-0 top-[108px] bg-black bg-opacity-70 z-40 overflow-hidden"
  *ngIf="
    activeSubMenuIndex() !== -1 && menu[activeSubMenuIndex()]?.children?.length
  "
></div>
} }

<ng-template #groupTemp let-menu>
  <div class="grid grid-cols-2 gap-y-7 gap-x-12 font-bold mb-auto">
    <div *ngIf="menu.label" class="capitalize font-bold col-span-full">
      {{ menu.label | translate }}
    </div>
    @for(item of menu.children; track item) {
    <!-- Template placer according to type of menu items -->
    @switch (item.type) { @case ('card') {
    <ng-container
      [ngTemplateOutlet]="cardTemp"
      [ngTemplateOutletContext]="{ $implicit: item }"
    ></ng-container>
    } @case ('banner') {
    <ng-container
      [ngTemplateOutlet]="bannerTemp"
      [ngTemplateOutletContext]="{ $implicit: item }"
    ></ng-container>
    } @case ('group') {
    <ng-container
      [ngTemplateOutlet]="groupTemp"
      [ngTemplateOutletContext]="{ $implicit: item }"
    ></ng-container>
    } @default {
    <ng-container
      [ngTemplateOutlet]="basicTemp"
      [ngTemplateOutletContext]="{ $implicit: item }"
    ></ng-container>
    } } }
  </div>
</ng-template>

<ng-template #basicTemp let-item>
  <a [routerLink]="item?.link" class="capitalize">{{ item?.name }}</a>
</ng-template>

<ng-template #cardTemp let-item>
  <a class="flex gap-2 font-normal" [routerLink]="item.link">
    <img
      class="w-10 h-10 rounded-full object-cover"
      [src]="item.data.image | addCdn"
    />
    <div class="flex flex-col justify-center">
      <div class="text-sm">{{ item.data.name }}</div>
      <div class="flex text-neutral-500 text-[10px]/5 gap-0.5">
        <span>{{ item.data.place }}</span>
        <span>-</span>
        <span>{{ item.data.startDate | date : "d MMMM, EEEE" }}</span>
      </div>
    </div>
  </a>
</ng-template>

<ng-template #bannerTemp let-item>
  <a class="col-span-full" [routerLink]="item.link">
    <img
      class="w-full h-auto object-cover aspect-square object-top"
      [src]="item.data.image | addCdn"
    />
  </a>
</ng-template>
