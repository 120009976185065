export const environment = {
  production: false,
  apiHost: 'https://apiv2.biletzero.xyz/api/v1/',
  gmapApiKey: 'AIzaSyCyXmnKkkz8EEnYQDg0jUMzHSlItjHmEGs',
  cdn: 'https://cdncloud.biletzero.com/',
  domain: 'biletzero.xyz',
  refAffStorageTime: 24 * 60 * 60 * 1000,
  salesChannel: 'bzcom',
  multiCityDisabled: false,
  initialCity: 'Türkiye',
};
